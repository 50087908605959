/**
 * Обработка ошибок. Если в data что-то есть, брать оттуда ошибку, иначе из message
 * !!! Тут есть параметр isFormError, но можно вместо него юзать отдельную
 * функцию formHandleErrors (более очевидное разделение + в отдельной функции обрабатывается _alert)
 *
 * @param {Object} error объект с ошибкой с бекенда
 * @param {String} message сообщение, если ошибки в data нет
 * @param {Boolean} isFormError является ли ошибки, ошибками формы?
 */
function handleErrors(error, message = "Ошибка", isFormError = false) {
	// Если в error нет data - сами пишем ошибку (возвращаем в виде массива для унификации)
	if (!error || !error.data || error.data.length === 0) {
		return isFormError ? { fields: [], message } : message
	}

	// Если data есть - составляем массив ошибок
	const errors = []
	const fields = Object.keys(error.data)
	const dataArray = Object.entries(error.data)

	/**
	 * Каждый элемент этого массива - массив вида [option, value].
	 * Если value - массив, то пушим в массив ошибок каждый элемент массива value.
	 * Если value - не массив, то скорее всего это просто строка. Пушим ее в массив ошибок.
	 */
	dataArray.forEach(dataItem => {
		const value = dataItem[1]
		const isArray = Array.isArray(value)

		if (isArray) {
			value.forEach(valueItem => {
				errors.push(valueItem)
			})
		} else {
			errors.push(value)
		}
	})

	message = errors.join("; ")

	return isFormError ? { fields, message } : message
}

/**
 * Особый обработчик ошибки, чтобы в алерте показывать кратко, а в инпутах - подробно
 * @param {Array} errors
 * @param {String} message
 * @returns {Object} { message, alert, fields }
 */
function formHandleErrors(errors, message) {
	const normalizedData = {}
	const fields = {}

	Object.entries(errors.data).forEach(([name, error]) => {
		if (name != "_alert") {
			normalizedData[name] = error
			fields[name] = error[0]
		}
	})

	const errorsWithoutAlert = { ...errors, data: normalizedData }
	const alert = errors.data._alert || handleErrors(errorsWithoutAlert, message)

	return { fields, alert }
}

export { handleErrors, formHandleErrors }
