import axios from "axios"
import qs from "qs"
import EventBus from "./EventBus"
import LocalStorage from "./localStorage"

const STATUS_CODE_UNAUTHORIZED = 401

/**
 * HTTP обращения к серверу
 */
export default {
	/**
	 * Получить настройки AJAX запросов к HTTP
	 */
	get settings() {
		return {
			baseURL: process.env.VUE_APP_BACKEND_HOST,
			responseType: "json",
			headers: {
				"X-Auth-Token": LocalStorage.get("token", null),
				"X-Frontend-Domain": process.env.VUE_APP_FRONTEND_HOST,
				"X-Requested-With": "XMLHttpRequest"
			}
		}
	},

	/**
	 * Создать HEAD запрос
	 * @param {String} url
	 * @param {Object} query
	 * @param {Object} headers
	 * @param {Object} options
	 */
	async head(url, query = {}, headers = {}, options = {}) {
		const response = await this.request("head", url, query, {}, headers, {
			...options,
			fullResponse: true
		})

		return response.headers
	},

	/**
	 * Создать GET запрос
	 * @param {String} url
	 * @param {Object} query
	 * @param {Object} headers
	 * @param {Object} options
	 */
	async get(url, query = {}, headers = {}, options = {}) {
		return await this.request("get", url, query, {}, headers, options)
	},

	/**
	 * Создать POST запрос
	 * @param {String} url
	 * @param {Object} data
	 * @param {Object} headers
	 * @param {Object} options
	 */
	async post(url, data = {}, headers = {}, options = {}) {
		return await this.request("post", url, {}, data, headers, options)
	},

	/**
	 * Создать POST запрос
	 * @param {String} url
	 * @param {Object} data
	 * @param {Object} headers
	 * @param {Object} options
	 */
	async postAxios(url, data = {}, headers = {}) {
		return axios.post(url, data, headers)
	},

	/**
	 * Создать PUT запрос
	 * @param {String} url
	 * @param {Object} data
	 * @param {Object} headers
	 * @param {Object} options
	 */
	async put(url, data = {}, headers = {}, options = {}) {
		return await this.request("put", url, {}, data, headers, options)
	},

	/**
	 * Создать DELETE запрос
	 * @param {String} url
	 * @param {Object} data
	 * @param {Object} headers
	 * @param {Object} options
	 */
	async delete(url, data = {}, headers = {}, options = {}) {
		return await this.request("delete", url, {}, data, headers, options)
	},

	/**
	 * Скачать бинарный файл
	 * @param {String} url
	 * @param {String} filename
	 * @param {Object} data
	 * @param {Object} headers
	 * @param {Object} options
	 */
	async download(url, filename, data = {}, headers = {}, options = {}) {
		options = {
			...options,
			method: options.method || "get",
			responseType: "blob",
			qsParser: true
		}

		let blob = null

		if (options.method.toLowerCase() == "get") {
			blob = await this.get(url, data, headers, options)
		} else if (options.method.toLowerCase() == "post") {
			blob = await this.post(url, data, headers, options)
		}

		// Если перед скачиванием надо задать вопрос (исходя из параметров файла)
		if (options.question) {
			return { blob, filename }
		} else {
			// Если вопросы не нужны, просто скачать
			this.downloadBlob(filename, blob)
		}

		return blob
	},

	/**
	 * Скачать файл из хранилища
	 * @param {String} url
	 * @param {String} filename
	 */
	async downloadFromStorage(url, filename) {
		return await this.download(
			url,
			filename,
			{},
			{},
			{
				requestData({ url, query }) {
					return {
						url,
						method: "get",
						responseType: "blob",
						params: query,
						qsParser: true
					}
				}
			}
		)
	},

	/**
	 * Скачать файл по HREF
	 * @param {String} filename
	 * @param {String} href
	 */
	downloadHref(filename, href) {
		const link = document.createElement("a")

		link.setAttribute("href", href)
		link.setAttribute("download", filename)

		link.style.display = "none"
		document.body.appendChild(link)

		link.click()
		link.remove()
	},

	/**
	 * Скачать банрник
	 * @param {String} filename
	 * @param {Object} blob
	 */
	downloadBlob(filename, blob) {
		this.downloadHref(filename, window.URL.createObjectURL(new Blob([blob])))
	},

	/**
	 * Скачать Base64 как файл
	 * @param {String} filename
	 * @param {String} base64Text
	 */
	downloadBase64(filename, base64Text) {
		this.downloadHref(filename, `data:application/octet-stream;base64,${encodeURIComponent(base64Text)}`)
	},

	/**
	 * Загрузить бинарный файл
	 * @param {String} url
	 * @param {Object} data
	 * @param {Object} headers
	 * @param {Object} options
	 */
	async upload(url, data = {}, headers = {}, options = {}) {
		const form = new FormData()

		headers = {
			"Content-Type": "multipart/form-data",
			...headers
		}

		Object.entries(data).forEach(([field, value]) => {
			form.append(field, value)
		})

		return await this.post(url, form, headers, options)
	},

	/**
	 * Создать запрос
	 * @param {String} method
	 * @param {String} url
	 * @param {Object} query
	 * @param {Object} data
	 * @param {Object} headers
	 * @param {Object} options
	 */
	async request(method, url, query = {}, data = {}, headers = {}, options = {}) {
		headers = { ...this.settings.headers, ...headers }

		let requestData = {}

		if (options.requestData instanceof Function) {
			requestData = options.requestData.call(this, {
				method,
				url,
				query,
				data,
				headers,
				...(options.axiosApi ?? {})
			})
		} else {
			requestData = {
				url,
				method,
				headers,
				responseType: typeof options.responseType !== "undefined" ? options.responseType : this.settings.responseType,
				baseURL: typeof options.baseURL !== "undefined" ? options.baseURL : this.settings.baseURL,
				params: query,
				data,
				...(options.axiosApi ?? {})
			}
		}

		if (options.qsParser) {
			requestData.paramsSerializer = params => {
				return qs.stringify(params, {
					skipNulls: true,
					encodeValuesOnly: true
				})
			}
		}

		try {
			const response = await axios(requestData)

			// Получить полный ответ
			if (options.fullResponse) {
				return response
			}

			return response.data
		} catch (error) {
			// Если не пропустил CORS или же получен статус 401
			if (error.response.status === STATUS_CODE_UNAUTHORIZED) {
				EventBus.emit("api:request-get-401", error)
				// 	// Не бросать исключение (для предотвращения лишних уведомлений об ошибках)
				// 	return error
			}

			throw error.response.data
		}
	}
}
